/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Form, Input, Button, Bubble } from 'tea-component';
import { GetTxList } from '@src/utils/apis';
import { Tx } from '@src/models';
import { formatUnixTime } from '@src/utils/tools';
import { Link } from 'react-router-dom';
import { RangePicker } from 'tea-component/lib/datepicker/RangePicker';
import moment from 'moment';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
const { pageable, autotip } = Table.addons;

export default function Transaction() {
  const now = new Date().getTime();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [value, setValue] = useState<string>('');
  const [sender, setSender] = useState<string>('');
  const [userAddr, setUserAddr] = useState<string>('');
  const [starttime, setStarttime] = useState<number>(new Date('2020').getTime());
  const [endtime, setEndtime] = useState<number>(now);
  const [list, setList] = useState<Tx[]>([]);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);
  const updateTime = useCallback((value) => {
    setStarttime(value[0].toDate().getTime());
    setEndtime(value[1].toDate().getTime());
  }, []);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetTxList({
      ChainId: chainId,
      Limit: pageSize,
      TxId: value,
      Sender: sender,
      UserAddr: userAddr,
      Offset: pageIndex - 1,
      StartTime: Math.round(starttime / 1000),
      EndTime: Math.round(endtime / 1000),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, starttime, endtime, value, sender, userAddr, pageIndex, pageSize]);
  const columns = useMemo<TableColumn<Tx>[]>(() => {
    const list: TableColumn<Tx>[] = [
      {
        key: 'BlockHeight',
        header: '区块高度',
        align: 'left',
        width: 100,
        render: ({ BlockHeight }) => <Link to={`/${chainId}/block/${BlockHeight}`}>{BlockHeight}</Link>,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'UserAddr',
        header: '发起用户',
        align: 'left',
        render:
          authType === 'permissionedwithcert'
            ? ({ Sender, UserAddr }) => <Bubble content={UserAddr}>{Sender}</Bubble>
            : ({ UserAddr }) => UserAddr || '--',
      },
      {
        key: 'ContractName',
        header: '目标合约',
        align: 'left',
        width: 140,
        render: ({ ContractName }) => <Link to={`/${chainId}/contract/${ContractName}`}>{ContractName}</Link>,
      },
      {
        key: 'Status',
        header: '交易状态',
        align: 'left',
        width: 140,
        render: ({ Status }) => <Text theme={Status === 'SUCCESS' ? 'success' : 'danger'}>{Status}</Text>,
      },
      {
        key: 'Timestamp',
        header: '上链时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TxId }) => (
          <div className={liststyle.setting_c}>
            <Link to={`/${chainId}/transaction/${TxId}`}>查看</Link>
          </div>
        ),
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(2, 0, {
        key: 'SenderOrg',
        header: '发起组织',
        align: 'left',
      });
    }
    return list;
  }, []);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  return (
    <PageBox title="交易列表">
      <Justify
        right={
          <>
            <Form className={liststyle.searchform} hideLabel={false} layout="inline">
              <Form.Item label="起止时间">
                <RangePicker
                  defaultValue={[moment(starttime), moment(endtime)]}
                  onChange={updateTime}
                  onOpenChange={(open) => console.log(open ? 'open' : 'close')}
                />
              </Form.Item>
              {authType === 'permissionedwithcert' && (
                <Form.Item label="用户名">
                  <Input onChange={(value) => setSender(value)} value={sender} placeholder="请输入用户名" />
                </Form.Item>
              )}
              <Form.Item label="用户地址">
                <Input onChange={(value) => setUserAddr(value)} value={userAddr} placeholder="请输入用户地址" />
              </Form.Item>
              <Form.Item label="交易Id">
                <Input onChange={(value) => setValue(value)} value={value} placeholder="请输入交易Id搜索" />
              </Form.Item>
              <Form.Item label="">
                <Button type="primary" onClick={getList}>
                  筛选
                </Button>
              </Form.Item>
            </Form>
          </>
          // <SearchInput
          //   placeholder="输入交易Id搜索"
          //   onSubmit={getList}
          //   onChange={(value) => setValue(value)}
          //   value={value}
          // />
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="Id"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
